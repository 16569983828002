import { MODULES, isModuleEnabled } from 'Common/config';

/**
 * NOTE: This is for the /locations page - Map Search Page (active_modules: "map_search")
 * Returns true if map search is enabled in the config, else false
 * @param {object} config the PMC config from CS
 * @returns boolean
 */
export const mapSearchModuleEnabled = (config) => {
  return isModuleEnabled(config, MODULES.MAP_SEARCH);
};

/**
 * Returns the map search URL (string) for use in hrefs.
 * @param {string} currentQuery the current query string in the URL
 * @param {Object} additionalParams an object of additional query parameters to add to the URL
 * @returns string
 */
export const getMapSearchURL = (currentQuery, additionalParams) => {
  const baseUrl = '/locations';

  const searchParams = new URLSearchParams(currentQuery || {});

  Object.entries(additionalParams || {}).forEach(([param, value]) => {
    if (!searchParams.get(param)) {
      searchParams.append(param, value);
    }
  });

  const querystring = searchParams.toString();

  return querystring ? `${baseUrl}?${querystring}` : baseUrl;
};

/**
 * Returns the location id (not mloc id) (string) for use in DBW booking online url.
 * @param providers {array} list of providers
 * @param mlocId {string} mloc id
 * @returns {number|undefined}
 */
export const getLocationIdForBookingUrl = (providers, mlocId) => {
  for (let i = 0; i < providers.length; i++) {
    const mloc = providers[i]?.locations?.find((loc) =>
      loc.associated_marketable_location_ids?.includes(mlocId)
    );
    if (mloc) return mloc.id;
  }
};

export const getApptOptions = (providers, mlocId) => {
  const locationId = getLocationIdForBookingUrl(providers, mlocId);
  return locationId
    ? {
        location_id: locationId
      }
    : {};
};
