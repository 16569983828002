import styled from '@emotion/styled';
import { fromTheme } from '@kyruus/ui-theme';
import { Link } from 'react-router-dom';

export const ClearAllFiltersLink = styled(Link)`
  font-size: ${fromTheme('font_size')};
`;

export const SearchSummaryText = styled('h3')`
  font-size: ${fromTheme('font_size')};
  margin-right: ${fromTheme('spacing_medium')};
`;
