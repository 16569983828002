import React from 'react';
import { defineMessages, FormattedMessage } from '@kyruus/intl';
import { Link } from 'react-router-dom';
import _startCase from 'lodash/startCase';
import _forEach from 'lodash/forEach';

export const messages = defineMessages({
  bestmatch: {
    id: 'sortdropdown.bestmatch',
    description: 'Option for a relevance sort',
    defaultMessage: 'Best Match'
  },
  distance: {
    id: 'sortdropdown.distance',
    description: 'Option for a distance sort',
    defaultMessage: 'Distance'
  },
  availability: {
    id: 'sortdropdown.availability',
    description: 'Option for an availability sort (density)',
    defaultMessage: 'Availability'
  },
  availabilitysoonest: {
    id: 'sortdropdown.availabilitysoonest',
    description: 'Option for an availability sort (soonest)',
    defaultMessage: 'Availability'
  },
  alphabetical: {
    id: 'sortdropdown.alphabetical',
    description: 'Option for an alphabetical sort',
    defaultMessage: 'A-Z'
  },
  reversealphabetical: {
    id: 'sortdropdown.reversealphabetical',
    description: 'Option for a reverse-alphabetical sort',
    defaultMessage: 'Z-A'
  },
  sortby: {
    id: 'resultsheader.sortby',
    description: 'Header for a sort dropdown',
    defaultMessage: 'Sort by'
  },
  sortarialabel: {
    id: 'resultsheader.sortbyarialabel',
    description: 'Header for Sorting options region',
    defaultMessage: 'Sorting options'
  }
});

function SortMenuItem({
  sortOption,
  searchSummary,
  getUpdatedSearch,
  idPrefix
}) {
  const SORT_BY_PREFIX = `${idPrefix}sort-by`;
  let sortById = `${SORT_BY_PREFIX}-${sortOption.value}`;

  let itemContent = {};
  if (sortOption.value == searchSummary.sort) {
    itemContent = (
      <strong id={sortById} data-testid={sortById} aria-current={true}>
        <FormattedMessage {...sortOption.descriptor} />
      </strong>
    );
  } else {
    itemContent = (
      <Link
        id={sortById}
        data-testid={sortById}
        to={getUpdatedSearch([
          { action: 'append', key: 'sort', value: sortOption.value }
        ])}
      >
        <FormattedMessage {...sortOption.descriptor} />
      </Link>
    );
  }
  return <span className="sort-menu-item">{itemContent}</span>;
}

function SortMenu({
  searchSummary,
  sortOptions,
  getUpdatedSearch,
  idPrefix = ''
}) {
  _forEach(sortOptions, (option) => {
    option.descriptor = messages[option.descriptor_id] || {
      id: `sortdropdown.${option.descriptor_id}`,
      defaultMessage: _startCase(option.descriptor_id),
      description: `Option for ${option.descriptor_id} sort`
    };
  });

  let sortMenuNodes = sortOptions.map((sortOption) => {
    return (
      <SortMenuItem
        key={sortOption.value}
        sortOption={sortOption}
        searchSummary={searchSummary}
        getUpdatedSearch={getUpdatedSearch}
        idPrefix={idPrefix}
      />
    );
  });

  return (
    <section aria-labelledby="sortarialabel">
      <h2 className="sr-only" id="sortarialabel">
        <FormattedMessage {...messages.sortarialabel} />
      </h2>
      <span id={`${idPrefix}sort-by`} data-testid="SortMenu">
        <span className="sr-only">
          <FormattedMessage {...messages.sortby} />
        </span>
        {sortMenuNodes}
      </span>
    </section>
  );
}

export default SortMenu;
