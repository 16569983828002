import React, { useState, useMemo } from 'react';
import { defineMessages, injectIntl, FormattedMessage } from '@kyruus/intl';

import { withRouter } from 'react-router-dom';

import ProviderTileNext, {
  PLACEMENT_TYPE_SEARCH
} from '@kyruus/provider-tile-next';

import {
  ProviderReferralButton,
  ProviderReferralList
} from '@kyruus/provider-referral';

import { fromTheme } from '@kyruus/ui-theme';
import { useTheme } from 'emotion-theming';

import CtaModal from '../cta-modal';

import {
  getProviderUrl,
  getProviderSummaryUrl,
  getProviderDisplayName
} from '../provider/utils';
import { ContactPanel } from '../provider/scheduling-options';
import { pages } from '../tracking/constants';
import { pageViewEvent } from '../tracking/tracking-utils';
import { getLogWithProvider } from '../tracking/decorators';
import { getRelativeParameterizedBookingUrl } from '../utils/getRelativeParameterizedBookingUrl';
import { locationsModuleEnabled, buildLocationURL } from '../utils/location';

import { MAX_AVAILABILITIES_TO_DISPLAY } from '../utils/constants';
import { scrollToTopCCF } from '../utils/cerner';
import {
  useReferralCrmConnector,
  InvalidPatientDataMessage
} from '../referral-crm-connector';
import {
  MODULES,
  isModuleEnabled,
  shouldRenderDirectBookInDrawer
} from 'Common/config';

const messages = defineMessages({
  header: {
    id: 'cta.modal.header',
    description: 'Book an appointment call to action modal header',
    defaultMessage: 'Book an Appointment with {providerName}'
  },
  moreAvailabilitiesLinkText: {
    id: 'availability.moreavailabilities',
    description: 'Text to display on link to view more availabilities',
    defaultMessage: 'View All Appointments'
  },
  availabilityErrorMsg: {
    id: 'availability.error',
    description: 'Message to display when call to availability fails',
    defaultMessage:
      "We're currently experiencing difficulties fetching availability for {displayName}."
  },
  availabilityNoMoreRetriesMsg: {
    id: 'availability.nomoreretries',
    description:
      'Message to display when user can no longer retry call to get slots',
    defaultMessage:
      "Unfortunately we can't fetch availability for {displayName} right now. Please call {phoneNumber} to schedule an appointment."
  },
  availabilityTryAgainBtnText: {
    id: 'availability.tryagain',
    description: 'Text for try availability "try again" button',
    defaultMessage: 'Try again.'
  },
  showAvailabilitiesBtnText: {
    id: 'availability.showavailabilities',
    description: 'Text for availability CTA button',
    defaultMessage: 'Show Available Appointments'
  },
  showAvailabilitiesBtnAriaLabelText: {
    id: 'availability.showAvailabilitiesBtnAriaLabel',
    description:
      'Accessibility text to be read by screen reader for availability CTA button',
    defaultMessage: 'Show Available Appointments for {providerName}'
  },
  noAvailabilityMessage: {
    id: 'availability.noavailability',
    description: 'Message to display when a location has no available slots',
    defaultMessage:
      "{displayName} doesn't have any availability for this location."
  },
  providerAvailabilitySlotsAriaLabel: {
    id: 'availability.slotsAriaLabel',
    description:
      'Accessibility text to be read by screen reader for provider available slots',
    defaultMessage: '{date} and {time} with {providerName} at {locationName}'
  },
  providerAvailabilityViewMoreAriaLabel: {
    id: 'availability.viewMoreAriaLabel',
    description:
      'Accessibility text to be read by screen reader for provider view more slots',
    defaultMessage: 'view all available appointments for {providerName}'
  }
});

// constants for the values of the `from` url param when passing from providers list to profile pages
export const FROM_SEARCH_LIST = 'search-list';
export const FROM_SEARCH_MAP = 'search-map';
export const FROM_LOCATIONS = 'locations';

function ProviderList({
  // array of providers
  providers = [],
  customerCode,
  productName,
  // customer config
  config,
  // ?
  searchSummary,
  // callback for logging to kloggyr
  log,
  // url matched params from react-router
  match,
  // window location from react-router
  location,
  // history object for navigation from react-router
  history,
  // ?
  onClickShowAvailability,
  // window opening target (ie _self, _blank) for avail tiles clicks
  availabilityLinkTarget,
  // callback for "onTimesSlots"
  onTimeSlotsClick,
  // callback when clicking view more availability tiles button
  onViewMoreClick,
  // callback when availability tiles are loaded
  onAvailabilityTilesLoaded,
  // ?
  slots,
  // ?
  slotsStatus,
  // extra params to send to send along to provider profile pages
  profileSummaryParams = {},
  // provider title and ratings callback function
  providerCallbackFunc = () => {},
  // booking appt button callback function
  bookingCallbackFunc = () => {},
  // tokens from pmc redux state
  tokens = {},
  // appointment options to be passed as deepLinkingParam
  apptOptions,
  intl
}) {
  const theme = useTheme();
  const color_text_contrast = fromTheme('color_text_contrast')({ theme });

  // State for populating and triggering modal
  const [selectedProvider, setSelectedProvider] = useState(undefined);

  const isLocationsModuleEnabled = locationsModuleEnabled(config);

  const { enabled: crmEnabled, connector } = useReferralCrmConnector(config);

  // Get modal display
  const {
    modal_display: {
      skip_modal: skipModal,
      virtual_care: virtualCareOrg,
      show_book_online_only_with_availability:
        showBookOnlineOnlyWithAvailability
    } = {}
  } = config;

  const logWithSelectedProvider = useMemo(
    () => getLogWithProvider(log, selectedProvider),
    [log, selectedProvider]
  );

  const bookingCallbackFunction = (provider) => {
    return (_e) => {
      bookingCallbackFunc(provider);
      const providerBookUrl = provider.book_online_url;
      const providerVirtualCareUrl = provider.virtual_care_url;
      const virtualCareEnabled = providerVirtualCareUrl && virtualCareOrg;
      // the boolean skipModal comes from an org's config and indicates that the call to action button should bypass the modal
      // however, this feature is only active if the org does not have both direct booking and virtual care configured
      // in the case that skipModal is turned on and direct booking and virtual care are active,
      // we render the modal so the user can select which option they prefer.
      // If we are rendering direct book in a drawer, always skip the modal.
      const canSkipModal =
        shouldRenderDirectBookInDrawer(config) ||
        (skipModal &&
          (providerBookUrl || virtualCareEnabled) &&
          !(providerBookUrl && virtualCareEnabled));
      if (canSkipModal) {
        const url =
          getRelativeParameterizedBookingUrl({
            provider,
            config,
            tokens,
            location
          }) || provider.virtual_care_url;
        if (shouldRenderDirectBookInDrawer(config)) {
          // Push the current URL onto the history stack
          // along with the selected provider data as part of the location state
          // as required for react-router v4 modal routing.
          // See https://v5.reactrouter.com/web/example/modal-gallery for more info.
          history.push(url, {
            provider: {
              location,
              provider
            }
          });
        } else if (shouldOpenDirectBookSameWindow()) {
          window.open(url, '_self');
        } else {
          const win = window.open(url, '_blank');
          if (win != null) {
            win.focus();
          }
        }
      } else {
        scrollToTopCCF();
        // treat showing the modal as a "page event"
        getLogWithProvider(log, provider)(pageViewEvent(pages.CTA_MODAL));

        setSelectedProvider(provider);
      }
    };
  };

  const providerCallbackFunction = (provider, hash = '', slots) => {
    return (e) => {
      providerCallbackFunc(provider);
      const url = getProviderSummaryUrl(
        provider,
        hash,
        slots,
        profileSummaryParams,
        location,
        match
      );
      if (e.ctrlKey || e.metaKey) {
        window.open(url);
      } else {
        // force a server side render so that the redux context is reset with the search v9 provider (vs the v8 provider)
        window.open(url, '_self');
        // TODO: once search v9 migration is complete on the searchmatch page, switch to the more performant react-router history:
        // history.push(url);
      }
    };
  };

  const hideBookingModal = (event) => {
    // The close event triggered by the close button passes through an event, while the backdrop does not
    const closeTrigger = event ? 'close_icon' : 'backdrop';
    getLogWithProvider(
      log,
      selectedProvider
    )(`user_action.alter_view.request_appointment.${closeTrigger}`);
    setSelectedProvider(undefined);
  };

  const shouldOpenDirectBookSameWindow = () => {
    return isModuleEnabled(config, MODULES.DIRECT_BOOK);
  };

  const getProviderContactPhone = (provider) => {
    const phoneContact = provider.contacts.find((contact) => {
      return contact.contact_type === 'phone';
    });
    if (phoneContact) {
      return phoneContact.value;
    }
  };

  const locationCallbackFunction = (locationUrl) => {
    log('user_action.display_location_profile_page');
    history.push(locationUrl);
  };

  let bookingModal = null;

  if (selectedProvider) {
    // Variables for panel creation
    const providerId = selectedProvider.id;
    const providerBookUrl = selectedProvider.book_online_url;
    const providerHasAvailability = selectedProvider.has_provider_availability;
    const providerPhone = getProviderContactPhone(selectedProvider);
    const providerRequestUrl = selectedProvider.request_appointment_url;
    const providerVirtualCareUrl = selectedProvider.virtual_care_url;
    const virtualCareEnabled = providerVirtualCareUrl && virtualCareOrg;

    const bookUrlTarget = shouldOpenDirectBookSameWindow()
      ? '_self'
      : undefined;

    let bookOnline, phone, requestForm, virtualCare;

    if (providerBookUrl) {
      // provider availability will only be considered when config is true
      if (
        !showBookOnlineOnlyWithAvailability ||
        (showBookOnlineOnlyWithAvailability && providerHasAvailability)
      ) {
        bookOnline = (
          <ContactPanel
            target={bookUrlTarget}
            contact={getRelativeParameterizedBookingUrl({
              provider: selectedProvider,
              config,
              tokens,
              location,
              apptOptions
            })}
            contactType={'book_online'}
            log={logWithSelectedProvider}
            providerId={providerId}
          />
        );
      }
    }
    if (providerPhone) {
      phone = (
        <ContactPanel
          contact={providerPhone}
          contactType={'phone'}
          log={logWithSelectedProvider}
          providerId={providerId}
        />
      );
    }
    if (providerRequestUrl) {
      requestForm = (
        <ContactPanel
          contact={providerRequestUrl}
          contactType={'request_form'}
          log={logWithSelectedProvider}
          providerId={providerId}
        />
      );
    }
    if (virtualCareEnabled) {
      virtualCare = (
        <ContactPanel
          contact={providerVirtualCareUrl}
          contactType={'virtual_care'}
          log={logWithSelectedProvider}
          providerId={providerId}
        />
      );
    }

    const panels = [bookOnline, requestForm, phone, virtualCare];
    let panelCount = panels.filter(Boolean).length;

    if (
      showBookOnlineOnlyWithAvailability &&
      !providerHasAvailability &&
      panels.every((panel) => !panel)
    ) {
      panels.push(
        <ContactPanel
          contact={null}
          contactType={'no_availability_fallback'}
          log={logWithSelectedProvider}
          providerId={providerId}
          handleCloseCtaModal={hideBookingModal}
        />
      );
      // design wants the fallback panel to take up the space of 2 normal panels
      // setting panelCount to 2 achieves this requirement
      // https://kyruus.jira.com/browse/KENG-40044 for details
      panelCount = 2;
    }

    bookingModal = (
      <CtaModal
        id="appt-request"
        title={
          <FormattedMessage
            {...messages.header}
            values={{
              providerName: getProviderDisplayName(selectedProvider)
            }}
          />
        }
        panels={panels}
        open={Boolean(selectedProvider)}
        onClose={hideBookingModal}
        panelCount={panelCount}
      />
    );
  }

  const getProviderSlotsAndApptInfo = (provider, slots) => {
    return slots && slots[provider.id] ? slots[provider.id] : {};
  };

  const getProviderSlotsStatus = (provider, slotsStatus) => {
    return slotsStatus && slotsStatus[provider.id]
      ? slotsStatus[provider.id]
      : null;
  };

  const providerCards = providers.map((provider, index) => {
    // from this point on the data access paths will need to change

    return (
      <ProviderTileNext
        applyV9SummaryData={true}
        provider={provider}
        customerCode={customerCode}
        productName={productName}
        providerLocations={provider.locations}
        bookOnlineUrl={getRelativeParameterizedBookingUrl({
          provider,
          config,
          tokens,
          location
        })}
        config={config}
        searchSummary={searchSummary}
        track={getLogWithProvider(log, provider)}
        bookingCallback={bookingCallbackFunction(provider)}
        reviewCallback={providerCallbackFunction(
          provider,
          '#profile-reviews',
          slots
        )}
        reviewUrl={getProviderUrl({
          provider,
          location,
          match,
          hash: '#profile-reviews'
        })}
        locationsUrl={getProviderUrl({
          provider,
          location,
          match,
          hash: '#profile-locations'
        })}
        locationsCallback={providerCallbackFunction(
          provider,
          '#profile-locations',
          slots
        )}
        summaryCallback={providerCallbackFunction(provider, '', slots)}
        summaryUrl={getProviderSummaryUrl(
          provider,
          '',
          slots,
          profileSummaryParams,
          location,
          match
        )}
        key={provider.id}
        lazyLoadImg={index > 2}
        onClickShowAvailability={() => onClickShowAvailability(provider)}
        availabilityTilesMessages={{
          moreAvailabilitiesLinkText: (
            <FormattedMessage {...messages.moreAvailabilitiesLinkText} />
          ),
          showAvailabilitiesBtnText: (
            <FormattedMessage {...messages.showAvailabilitiesBtnText} />
          ),
          noAvailabilityMessage: (
            <FormattedMessage
              {...messages.noAvailabilityMessage}
              values={{ displayName: getProviderDisplayName(provider) }}
            />
          ),
          availabilityErrorMsg: (
            <FormattedMessage
              {...messages.availabilityErrorMsg}
              values={{ displayName: getProviderDisplayName(provider) }}
            />
          ),
          availabilityNoMoreRetriesMsg: (
            <FormattedMessage
              {...messages.availabilityNoMoreRetriesMsg}
              values={{
                displayName: getProviderDisplayName(provider),
                phoneNumber: (() => {
                  const phone = provider.contacts.find(({ contact_type }) => {
                    return contact_type === 'phone';
                  });
                  if (phone) {
                    return phone.value;
                  }
                })()
              }}
            />
          ),
          availabilityTryAgainBtnText: (
            <FormattedMessage {...messages.availabilityTryAgainBtnText} />
          ),
          showAvailabilitiesBtnAriaLabel: intl.formatMessage(
            messages.showAvailabilitiesBtnAriaLabelText,
            {
              providerName: getProviderDisplayName(provider)
            }
          ),
          slotsAriaLabel: (date, time, locationName) =>
            intl.formatMessage(messages.providerAvailabilitySlotsAriaLabel, {
              date,
              time,
              providerName: getProviderDisplayName(provider),
              locationName
            }),
          viewMoreAriaLabel: intl.formatMessage(
            messages.providerAvailabilityViewMoreAriaLabel,
            {
              providerName: getProviderDisplayName(provider)
            }
          )
        }}
        availabilityLinkTarget={availabilityLinkTarget}
        maxAvailabilitiesToDisplay={MAX_AVAILABILITIES_TO_DISPLAY}
        onTimeSlotsClick={onTimeSlotsClick}
        onViewMoreClick={onViewMoreClick}
        onAvailabilityTilesLoaded={onAvailabilityTilesLoaded}
        getLocationUrl={(location) =>
          buildLocationURL(
            isLocationsModuleEnabled,
            location,
            searchSummary.query_string
          )
        }
        locationCallback={(locationUrl) =>
          locationCallbackFunction(locationUrl)
        }
        apptInfo={getProviderSlotsAndApptInfo(provider, slots).apptInfo}
        slots={getProviderSlotsAndApptInfo(provider, slots).slots}
        slotsStatus={getProviderSlotsStatus(provider, slotsStatus)}
        locationCTA={
          isModuleEnabled(config, MODULES.REFERRAL)
            ? (props) => (
                <ProviderReferralButton
                  referrals={connector.state.context.referrals}
                  onAddReferral={connector.addReferral}
                  onRemoveReferral={connector.removeReferral}
                  canRefer={connector.canRefer(connector.state)}
                  hasMaxReferrals={connector.hasMaxReferrals(connector.state)}
                  referableProvider={{ ...props, config: config }}
                />
              )
            : null
        }
        placementType={PLACEMENT_TYPE_SEARCH}
        bookingWarning={
          crmEnabled && (
            <InvalidPatientDataMessage
              type="errorBooking"
              invalidFields={
                connector.state.context.validationErrors.booking?.data
              }
              color={color_text_contrast}
            />
          )
        }
        hasBookingWarning={
          isModuleEnabled(config, MODULES.DIRECT_BOOK) &&
          crmEnabled &&
          connector.hasPatientValidationErrorsForBooking()
        }
        isBookingDisabled={crmEnabled && !connector.canBook(connector.state)}
        isProfileDisabled={!isModuleEnabled(config, MODULES.PROFILE)}
      />
    );
  });

  return (
    <div
      id="provider-list"
      data-testid="ProviderList"
      className="result-column"
    >
      {providerCards}
      {isModuleEnabled(config, MODULES.REFERRAL) && (
        <ProviderReferralList
          referrals={connector.state.context.referrals}
          maxReferralCount={connector.state.context.maxReferralCount}
          canRefer={connector.canRefer(connector.state)}
          hasReferrals={connector.hasReferrals(connector.state)}
          isReferDisabled={connector.isReferDisabled(connector.state)}
          showSpinner={connector.showSpinner(connector.state)}
          onRemoveReferral={connector.removeReferral}
          onSendReferralData={(referralMetaData) => {
            log('user_action.provider_detail.refer_provider', {
              referrals: connector.state.context.referrals.map((ref) => ({
                provider_id: ref.provider.id,
                location_id: ref.location.id
              }))
            });
            connector.sendReferralData(referralMetaData);
          }}
          referralMetaData={{ searchSummary }}
        />
      )}
      {bookingModal}
    </div>
  );
}

export default withRouter(injectIntl(ProviderList));
